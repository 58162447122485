import React from 'react'

import { ServicesProvider } from '@wicadu/stores-services'
import { ThemeProvider } from '@wicadu/arepa/ui'
import { ScannerProvider } from '@wicadu/scanner-js'

import { AuthProvider } from '@hooks/useAuth'
import colors from '@constants/theme-colors'

export const wrapRootElement = ({ element }) => (
  <ServicesProvider>
    <ThemeProvider colors={colors}>
      <ScannerProvider>
        <AuthProvider>{element}</AuthProvider>
      </ScannerProvider>
    </ThemeProvider>
  </ServicesProvider>
)
