import React, { useReducer, useEffect, useMemo } from 'react'

import { useInterceptor } from '@wicadu/stores-services'
import type { Image, StoreEmployee, User } from '@wicadu/stores-services'
import { isBrowser } from '@wicadu/arepa/utils'
import { navigate } from 'gatsby'

import { AuthContext, stateDefault, reducers, IAuthUtils } from '@hooks/useAuth'
import getURLSearchParams from '@utils/getURLSearchParams'

interface Props {
  children: React.ReactNode
}

function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer<typeof reducers>(reducers, stateDefault)
  const { setAccessToken } = useInterceptor()

  const authUtils: Partial<IAuthUtils> = useMemo(
    () => ({
      login: () => {
        const redirectTo: string = encodeURIComponent(window?.location.toString())
        const url = `${process.env.OAUTH_WEBAPP_HOST}?redirectTo=${redirectTo}`
        window?.location.replace(url)
      },
      restoreToken: (accessToken: string): void => {
        dispatch({ type: 'RESTORE_TOKEN', payload: { accessToken } })
      },
      restoreEmptyToken: (accessToken: string): void => {
        dispatch({ type: 'RESTORE_TOKEN', payload: { accessToken } })
      },
      restoreUser: (profile: User): void => {
        dispatch({ type: 'RESTORE_USER', payload: { profile } })
      },
      restoreUserFailed: () => {
        dispatch({ type: 'RESTORE_USER_FAILED' })
      },
      whoAmIIsLoading: (loading: boolean) => {
        dispatch({ type: 'WHO_AM_I_IS_LOADING', payload: { loading } })
      },
      addEmployedByStore: (employedByStore: StoreEmployee) => {
        dispatch({ type: 'ADD_EMPLOYED_BY_STORE', payload: { employedByStore } })
      },
      replaceStoreImage: (storeId: number, storeImage: Image) => {
        dispatch({ type: 'REPLACE_STORE_IMAGE', payload: { storeId, storeImage } })
      },
      replaceStoreEmployeeImage: (storeId: number, storeEmployeeImage: Image) => {
        dispatch({ type: 'REPLACE_STORE_EMPLOYEE_IMAGE', payload: { storeId, storeEmployeeImage } })
      },
      signOut: async () => {
        const url = `${process.env.OAUTH_WEBAPP_HOST}/logout`
        window?.location.replace(url)
      },
    }),
    [dispatch]
  )

  useEffect(() => {
    const bootstrapAsync = async () => {
      if (isBrowser()) {
        const urlParams: URLSearchParams = getURLSearchParams()
        const accessTokenFromParams: string = urlParams.get('accessToken')

        if (accessTokenFromParams) {
          const url = new URL(window?.location.toString())
          url.searchParams.delete('accessToken')

          const path: string = `${url.pathname}${url.search}`

          navigate(path, { replace: true })
        }

        setAccessToken(accessTokenFromParams)
        authUtils.restoreToken(accessTokenFromParams)
      }
    }

    bootstrapAsync()
  }, [])

  return (
    <AuthContext.Provider value={{ ...state, ...authUtils }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
